.stats-table {
  border-collapse: collapse;
  width: 100%;
}

.stats-table th,
.stats-table td {
  border: none;
  padding: 8px;
}

.stats-table th {
  background-color: white;
  border-bottom: 1px solid black;
}

.stats-table .stats-heading {
  font-size: 20px;
  font-weight: 600;
}

.statistics-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
}

.statistics-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  margin-right: 10px;
}

.search-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}

.label {
  flex: 1;
  font-weight: 500;
  font-size: 18px;
}

.select {
  flex: 2;
}

.search-button {
  flex: 0;
}

.snapshot-container {
  flex: 1;
  width: 50%;
}

.border-line {
  border-bottom: 2px solid #dee2e6;
  margin-top: 20px;
  width: 100%;
}

.detail-card {
  border-radius: 10px;
  border: 1px solid #ddd;
  padding: 15px;
  background-color: #f9f9f9;
}

.detail-card .card-title {
  color: #333;
}

.detail-card .card-text {
  font-size: 16px;
  color: #555;
}

/* Top 20 Page  */

.button-container {
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border: 1px solid #e0e0e0;
  margin-bottom: 20px;
}

.button-container:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

.section-title {
  font-weight: 500;
  color: #222;
  margin-bottom: 16px;
  font-size: 1.4rem;
}

.custom-button {
  background: linear-gradient(90deg, #007bff, #0056b3);
  border: none;
  transition: background 0.3s ease-in-out, transform 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;
  border-radius: 50px;
  padding: 12px 25px;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 123, 255, 0.4);
}

.custom-button:hover {
  background: linear-gradient(90deg, #0056b3, #004085);
  transform: translateY(-2px);
  box-shadow: 0 8px 12px rgba(0, 86, 179, 0.4);
}

.custom-button:active {
  background: linear-gradient(90deg, #004085, #003366);
  transform: translateY(0);
  box-shadow: 0 4px 6px rgba(0, 64, 133, 0.4);
}

.custom-button:not(:last-child) {
  margin-right: 10px;
}

.custom-button i {
  margin-right: 8px;
  padding: 6px;
  font-size: 1.1rem;
}
