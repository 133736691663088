/* Login.css */
body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  /* background-image: url(https://cdn.pixabay.com/photo/2016/03/27/19/54/golf-1284011_1280.jpg); */
  background-size: cover;
  background-position: center;
}



.overlay {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  width: 100%;
  height: 100%;
}

.login-form {
  background-color:white;
  padding: 20px;
  width: 80%;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  position: relative;
}

.login-title {
  color: #ff9900;
  font-size: 24px;
  margin-bottom: 20px;
}

.login-label {
  color: black;
  margin-bottom: 10px;
  display: block;
}

.login-input {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
}

.login-button {
  background-color: #ff9900;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #e68300;
}

/* Additional styles for responsiveness */
@media screen and (max-width: 600px) {
  .login-form {
    width: 90%;
  }
}


.login-button:disabled {
  opacity: 0.7; /* You can adjust the opacity to visually indicate the disabled state */
  cursor: not-allowed;
  background:black;
  /* Add any other styles you want for the disabled button */
}

/* Add styles for the pressed button (optional) */
.login-button.pressed {
  /* Add styles to make the button appear pressed */
  /* For example, you can add a darker background color or change the box-shadow */
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}