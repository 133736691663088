.custom-checkbox {
  min-width: 80px;
  height: 30px;
}
.activee {
  font-size: 12px;
}
.small-select {
  padding: 0.4rem 0.4rem;
  font-size: 0.875rem;
  line-height: 1.25;
  width: 60px;
}
