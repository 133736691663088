.troop-table-header, .troop-table-cell {
    border: 1px solid #dddddd;
  }
  
  .troop-table-header {
    background-color: #f2f2f2;
  }
  
  .troop-table-cell {
    padding: 8px;
  }
  