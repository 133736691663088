/* Updated Excel-like table styling with light blue colors */
.table-excel {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

/* For older browsers */
::-moz-selection {
  background-color: blue;
  color: white;
}

.table-excel th,
.table-excel td {
  border: 1px solid #ddd;
  padding-left: 8px;
  padding-top: 8px;

  padding-bottom: 8px;

  text-align: left;
  user-select: none;
}

.table-excel th {
  background-color: #e6f7ff; /* Light blue color for header */
}

.hh {
  background-color: #e6f7ff !important; /* Light blue color for header */
}

.table-excel tbody tr:hover {
  background-color: #cce5ff; /* Light blue color on hover */
}

/* Style for buttons */
.btn-excel {
  margin-top: 10px;
  margin-right: 10px;
}

.nn:hover {
  background-color: lightblue !important;
  padding-left: 2px;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
}
.hhh:hover {
  background-color: lightblue !important;
  padding-left: 10px;
  transition: all 0.3s ease-in-out;
}

/* PlayerRosterTable.css */

.resizable-column {
  position: relative;
}

.resizable-column.resizing {
  user-select: none;
}

.resizable-column::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 8px;
  cursor: col-resize;
  background: transparent;
}

#resizable-table {
  border-collapse: collapse;
  width: 100%;
}

#resizable-table th,
#resizable-table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

#resizable-table th {
  background-color: #f2f2f2;
  cursor: col-resize;
  user-select: none; /* To prevent text selection while dragging */
}

/* Your CSS file or style block */
input:disabled {
  background-color: white; /* Light gray background color */

  border: 0px solid white !important;
  color: black;
  padding: 0px;
  font-size: 16px;
}

.bi-x:hover {
  background-color: red;
  color: white;
  transition: all 0.3s ease-in-out;
  border-radius: 4px;
}

.nn1 {
  background-color: #e6f7ff !important;
}

td:focus {
  outline: 2px solid black;
}

.resizable_div {
  resize: horizontal;
  overflow: auto;
  padding: 0px;
  min-width: 250px;

  max-width: 500px;
  padding: 20px;
  padding-bottom: 10px;
}

.resizable-div::before {
  cursor: col-resize !important;
}

.resizable-div::after {
  cursor: col-resize !important;
}
