.tracker-table-header,
.tracker-table-cell {
  border: 1px solid #dddddd;
}

.tracker-table-header {
  background-color: white !important;
  white-space: nowrap;
  padding: 8px;
}

.tracker-table-cell {
  padding: 8px;
}
