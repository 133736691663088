body {
  background-color: #fff;
}

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  border: 1px solid #dddddd;
}

th {
  background-color: #f2f2f2;
}

select {
  width: 100%;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

@media (max-width: 900px) {
  th,
  td {
    white-space: nowrap;
    overflow: auto;
    text-overflow: ellipsis;
  }
}
