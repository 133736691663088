.farm-search-tab .form-group {
  margin-bottom: 1rem;
}

.farm-search-tab .form-group label {
  font-weight: bold;
}

.farm-search-tab .form-inline {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.farm-search-tab .form-inline .form-group {
  flex: 1;
  min-width: 200px;
}

.farm-search-tab .form-check {
  margin-bottom: 1rem;
  margin-top: 10px;
  padding: 0;
}

.farm-search-tab .form-check-label {
  font-size: 1rem;
  font-weight: bold;
}

.farm-search-tab .form-check-input {
  width: 70px;
  height: 25px;
  margin-left: 10px;
}

.farm-search-tab .table-responsive {
  margin-top: 20px;
}

.farm-search-tab table {
  width: 100%;
  border-collapse: collapse;
}

.farm-search-tab th,
.farm-search-tab td {
  border: 1px solid #ddd;
  padding: 8px;
}

.farm-search-tab th {
  background-color: #17a2b8;
  text-align: center;
}

.farm-search-tab .form-inline .switch-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.farm-search-tab .switch-group .form-check-label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.farm-search-tab .switch-group .form-check-input {
  margin-left: 10px;
}

.farm-search-tab .form-inline .btn-group {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.farm-search-tab .btn-search {
  width: 100%;
  max-width: 140px;
  margin: 20px auto;
  display: block;
  color: white;
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.farm-search-tab .btn-search:hover {
  transform: translateY(-3px);
}

.farm-search-tab .btn-search:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.farm-search-tab .btn-search.loading {
  pointer-events: none;
}

.farm-search-tab .btn-search .spinner-border {
  margin-right: 5px;
}
