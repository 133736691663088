.register-container {
  display: flex;
  justify-content: center;
  align-items: start;
}

.register-form {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 80%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}

.register-title {
  color: #ff9900;
  font-size: 24px;
  margin-bottom: 20px;
}

.green {
  color: green !important;
}
.red {
  color: red !important;
}

.error-message {
  color: red !important;
}

.register-input {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
}

.register-button {
  background-color: #ff9900;
  color: #1a1a1a;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.username-availability {
  color: red;
}

.register-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background: black;
}

.register-button.pressed {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
