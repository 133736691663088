.user-navbar {
    background-color: #ffffff; /* White background */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
    color: #000000; /* Black text */
    padding: 10px 0; /* Padding for improved spacing */
  }
  .navbar{
    min-height: 90px ;
  }
  
  .user-navbar .navbar-brand {
    font-weight: 500; 

    font-size: 30px;
  }
  
  .user-navbar .nav-link {
    color: #000000 !important;
    /* font-weight: 500; Medium font weight */
    padding: 0.5rem 1rem; /* Adjust padding for links */

    font-size: 18px;
  }
  
  .user-navbar .nav-link:hover {
    background-color: #3498db; /* Hover background color */
    color: #ffffff !important; /* Hover text color */
    border-radius: 4px; /* Add rounded corners on hover */
  }
  
  .user-navbar .navbar-toggler-icon {
    border-color: #000000; /* Color for the toggler icon */
  }
  
  .user-navbar .navbar-toggler:focus,
  .user-navbar .navbar-toggler:active {
    outline: none; /* Remove outline on focus/active for better appearance */
  }
  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .user-navbar .navbar-nav {
      margin-top: 10px; /* Adjust margin for better spacing on smaller screens */
    }
  }
  