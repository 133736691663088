/* AdminDashboard.css */

.admin-dashboard-container {
  padding: 20px;
  background: white;
  overflow-x: scroll;
}

.dashboard-title {
  text-align: center;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  overflow-x: scroll;
}

.user-row {
  border-bottom: 1px solid #ddd;
}

.user-table th,
.user-table td {
  padding: 12px;
}

.approve-button {
  background-color: #4caf50;
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Media query for responsiveness */
@media (max-width: 600px) {
  .user-table th,
  .user-table td {
    font-size: 12px;
    padding: 8px;
  }
}
/* Adjustments for Button Card Styling */
.button-card {
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
}

@media only screen and (max-width: 991.98px) {
  .button-card {
    gap: 16px;
    padding-top: 30px;
  }
}

.button-card .button-link {
  width: calc(16.6666666667% - 24px + 4px);
  padding: 24px 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: linear-gradient(to bottom, #eaecf0, #d4d7dc);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  cursor: pointer;
  gap: 20px;
}

@media only screen and (max-width: 1440px) {
  .button-card .button-link {
    padding: 14px 18px;
  }
}

@media only screen and (max-width: 1199.98px) {
  .button-card .button-link {
    width: calc(20% - 24px + 4.8px);
    border-radius: 8px;
  }
}

@media only screen and (max-width: 991.98px) {
  .button-card .button-link {
    width: calc(25% - 16px + 4px);
    border-radius: 8px;
  }
}

@media only screen and (max-width: 767.98px) {
  .button-card .button-link {
    width: calc(33.3333333333% - 16px + 5.3333333333px);
    border-radius: 8px;
  }
}

@media only screen and (max-width: 575.98px) {
  .button-card .button-link {
    width: calc(80% - 16px + 8px);
    border-radius: 8px;
  }
}

.button-card .button-link .button-icon {
  width: 76px;
  height: 76px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1440px) {
  .button-card .button-link .button-icon {
    width: 60px;
    height: 60px;
  }
}

.button-card .button-link .button-icon svg {
  color: #1b2533;
}

@media only screen and (max-width: 1440px) {
  .button-card .button-link .button-icon svg {
    width: 22px;
  }
}

.button-card .button-link .button-icon img {
  max-width: 40px;
}

.button-card .button-link p {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #1b2533;
}

@media only screen and (max-width: 1440px) {
  .button-card .button-link p {
    font-size: 14px;
  }
}

.button-card .button-link:hover,
.button-card .button-link.active {
  background: linear-gradient(to bottom, #2b82cc, #0d5aa7);
  transform: translateY(-3px);
}

.button-card .button-link:hover p,
.button-card .button-link.active p {
  color: #fff;
}

.move-back-button {
  border: none;
  border-radius: 50%;
  padding: 10px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.move-back-button:hover {
  background-color: rgba(211, 211, 211, 0.7);
}

.move-back-button img {
  display: block;
}
