.table-header,
.table-cell {
  border: 1px solid #dddddd;
}

.table-header {
  background-color: white !important;
  white-space: nowrap;
  padding: 0px !important;
}

.table-cell {
  padding: 8px;
  min-width: 100px !important;
}
.resizable-div {
  resize: horizontal;
  overflow: auto;
  min-width: 100px;
  max-width: 500px;
}
.resizable-div1 {
  resize: horizontal;
  overflow: auto;
  min-width: 350px;
  max-width: 500px;
}


